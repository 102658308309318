import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sidebarShow: true,
    asideShow: false,
    theme: 'default',
    sidebarUnfoldable: false,
};

export const themeSlice = createSlice({
    name: 'themeSlice',
    initialState,
    reducers: {
        setThemeAction: (state, action) => {
            state.theme = action?.payload;
        },
        setSideBarUnfoldableAction: (state, action) => {
            state.sidebarUnfoldable = action?.payload;
        },
        sideBarAction: (state, action) => {
            state.sidebarShow = action?.payload;
        },
        asideBarAction: (state, action) => {
            state.asideShow = action?.payload;
        }
    },
});

export const {
    setThemeAction,
    sideBarAction,
    asideBarAction,
    setSideBarUnfoldableAction
} = themeSlice.actions;

export default themeSlice.reducer;