import React from 'react';
import { useSelector } from 'react-redux';
import './scss/style.scss';
import AppRoute, {
  AuthRoute
} from './routes/pageRouter';
import { isNotNull } from './utils/constants';

function App() {
  const token = useSelector((state) => state?.authReducer?.data?.token);
  return (
    <div>
      {
        isNotNull(token) ?
          <AppRoute />
          :
          <AuthRoute />
      }
    </div>
  )
};

export default App;
