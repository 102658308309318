import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    error: null
}

export const bookingRulesSlice = createSlice({
    name: 'bookingRulesSlice',
    initialState,
    reducers: {
        bookingRuleSetSuccess: (state, action) => {
            state.data = action?.payload ?? {};
            state.error = null;
        },
        bookingRuleSetFailure: (state, action) => {
            state.data = null;
            state.error = action?.payload ?? "Something went wrong";
        }
    },
});

export const {
    bookingRuleSetSuccess,
    bookingRuleSetFailure
} = bookingRulesSlice.actions;

export default bookingRulesSlice.reducer;