import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    error: null
}

export const locStateSlice = createSlice({
    name: 'locStateSlice',
    initialState,
    reducers: {
        locStateSetSuccess: (state, action) => {
            state.data = action?.payload ?? [];
            state.error = null;
        },
        locStateSetFailure: (state, action) => {
            state.data = [];
            state.error = action?.payload ?? "Something went wrong";
        }
    },
});

export const {
    locStateSetSuccess,
    locStateSetFailure
} = locStateSlice.actions;

export default locStateSlice.reducer;