import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../reducers/auth.reducer';
import themeReducer from "../reducers/theme.reducer";
import roleMgmtReducer from "../reducers/roleManagement.reducer";
import bookingRulesReducer from "../reducers/bookingRules.reducer";
import locStateReducer from "../reducers/locState.reducer";

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    authReducer: authReducer,
    themeReducer: themeReducer,
    roleMgmtReducer: roleMgmtReducer,
    bookingRulesReducer: bookingRulesReducer,
    locStateReducer: locStateReducer
},);

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Custom middleware for logging
// const loggerMiddleware = store => next => action => {
//     console.log('Dispatching:', action);
//     let result = next(action);
//     console.log('Next State:', store.getState());
//     return result;
// };

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }),
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
});

export const persistor = persistStore(store);