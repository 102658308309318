import React from 'react';

import { logOutUser } from 'src/utils/constants'

const Profile = React.lazy(() => import('../views/profile/Profile'));

const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'));

// Masters
const UserMaster = React.lazy(() => import('../views/userMaster/UserMaster'));
const UserEntry = React.lazy(() => import('../views/userMaster/UserEntry'));

const VendorMaster = React.lazy(() => import('../views/vendorMaster/VendorMaster'));
const VendorEntry = React.lazy(() => import('../views/vendorMaster/VendorEntry'));

const VehicleTypeMaster = React.lazy(() => import('../views/vehicleTypeMaster/VehicleTypeMaster'));
const VehicleTypeEntry = React.lazy(() => import('../views/vehicleTypeMaster/VehicleTypeEntry'));

const VehicleMakeMaster = React.lazy(() => import('../views/vehicleMakeMaster/VehicleMakeMaster'));
const VehicleMakeEntry = React.lazy(() => import('../views/vehicleMakeMaster/VehicleMakeEntry'));

const CustomerMaster = React.lazy(() => import('../views/customerMaster/CustomerMaster'));
const CustomerEntry = React.lazy(() => import('../views/customerMaster/CustomerEntry'));

const ServiceLocation = React.lazy(() => import('../views/serviceLocationMaster/ServiceLocationMaster'));
const ServiceLocationEntry = React.lazy(() => import('../views/serviceLocationMaster/ServiceLocationEntry'));

const BookingMaster = React.lazy(() => import('../views/bookings/BookingMaster'));
const BookingEntry = React.lazy(() => import('../views/bookings/BookingEntry'));
const CustomerBookingMaster = React.lazy(() => import('../views/bookings/customerBookings/CustomerBookingMaster'));
const CustomerAddBooking = React.lazy(() => import('../views/bookings/customerBookings/CustomerAddBooking'));
const VendorBookingMaster = React.lazy(() => import('../views/bookings/vendorBookings/VendorBookingMaster'));
const ViewBooking = React.lazy(() => import('../views/bookings/ViewBooking'));

const InvoiceMaster = React.lazy(() => import('../views/invoiceMaster/InvoiceMaster'));
const InvoiceGenerate = React.lazy(() => import('../views/invoiceMaster/InvoiceGenerate'));
const InvoiceEntry = React.lazy(() => import('../views/invoiceMaster/InvoiceEntry'));
const InvoiceCustomerMaster = React.lazy(() => import('../views/invoiceMaster/customerInvoice/InvoiceCustomerMaster'));
const InvoiceViewDetails = React.lazy(() => import('../views/invoiceMaster/customerInvoice/InvoiceViewDetails'));

const PackageMaster = React.lazy(() => import('../views/packageMaster/packageMaster'));
const PackageEntry = React.lazy(() => import('../views/packageMaster/packageEntry'));

// Others
const RoleManagement = React.lazy(() => import('../views/roleManagement/RoleManagement'));

// My Accounts Details
const AccountDetails = React.lazy(() => import('../views/myAccounts/accountDetails/AccountDetails'));

// My Account KYC
const AccountKYC = React.lazy(() => import('../views/myAccounts/accountKYC/AccountKYC'));

// My Account Users
const AccountUsers = React.lazy(() => import('../views/myAccounts/accountUsers/AccountUsers'));
const AccountUserDetails = React.lazy(() => import('../views/myAccounts/accountUsers/AccountUserDetails'));
const AccountCreateUser = React.lazy(() => import('../views/myAccounts/accountUsers/AccountCreateUser'));

// My Account Vehicles
const AccountVehicles = React.lazy(() => import('../views/myAccounts/accountVehicles/AccountVehicles'));
const AccountVehicleDetails = React.lazy(() => import('../views/myAccounts/accountVehicles/AccountVehicleDetails'));
const AccountCreateVehicle = React.lazy(() => import('../views/myAccounts/accountVehicles/AccountCreateVehicle'));

//My Account Drivers
const AccountDrivers = React.lazy(() => import('../views/myAccounts/accountDrivers/AccountDrivers'));
const AccountDriverDetails = React.lazy(() => import('../views/myAccounts/accountDrivers/AccountDriverDetails'));
const AccountCreateDriver = React.lazy(() => import('../views/myAccounts/accountDrivers/AccountCreateDriver'));

//My Account Contact
const AccountContact = React.lazy(() => import('../views/myAccounts/accountContact/AccountContact'));
const AccountContactDetails = React.lazy(() => import('../views/myAccounts/accountContact/AccountContactDetails'));

//My Account Contracts
const AccountContracts = React.lazy(() => import('../views/myAccounts/accountContracts/AccountContracts'));
const AccountContractDetails = React.lazy(() => import('../views/myAccounts/accountContracts/AccountContractDetails'));

//My Account GST
const AccountGST = React.lazy(() => import('../views/myAccounts/accountGST/AccountGST'));

const Logout = () => {
  React.useEffect(() => {
    logOutUser();
  }, []);

  return null;
};

const routes = [
  { path: '/profile', name: 'Profile', element: Profile },
  
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },

  { path: '/service-location', name: 'Service Location', element: ServiceLocation },
  { path: '/service-location/entry', name: 'Entry', element: ServiceLocationEntry },

  { path: '/vehicle-type', name: 'Vehicle Type', element: VehicleTypeMaster },
  { path: '/vehicle-type/entry', name: 'Entry', element: VehicleTypeEntry },

  { path: '/vehicle-make', name: 'Vehicle Make', element: VehicleMakeMaster },
  { path: '/vehicle-make/entry', name: 'Entry', element: VehicleMakeEntry },

  { path: '/vendors', name: 'Vendors', element: VendorMaster },
  { path: '/vendors/entry', name: 'Entry', element: VendorEntry },
  { path: '/vendors/entry/:id', name: 'Entry', element: VendorEntry },

  { path: '/customers', name: 'Customers', element: CustomerMaster },
  { path: '/customers/entry', name: 'Entry', element: CustomerEntry },
  { path: '/customers/entry/:id', name: 'Entry', element: CustomerEntry },
  
  { path: '/bookings', name: 'Bookings', element: BookingMaster },
  { path: '/bookings/entry', name: 'Entry', element: BookingEntry },
  { path: '/bookings/entry/:id', name: 'Entry', element: BookingEntry },
  { path: '/customer-bookings', name: 'Bookings', element: CustomerBookingMaster },
  { path: '/customer-bookings/details/:id', name: 'Details', element: ViewBooking },
  { path: '/customer-bookings/entry', name: 'Entry', element: CustomerAddBooking },
  { path: '/vendor-bookings', name: 'Bookings', element: VendorBookingMaster },
  { path: '/vendor-bookings/details/:id', name: 'Details', element: ViewBooking },

  { path: '/invoices', name: 'Invoice', element: InvoiceMaster },
  { path: '/invoices/generate', name: 'Invoice', element: InvoiceGenerate },
  { path: '/invoices/entry', name: 'Entry', element: InvoiceEntry },
  { path: '/invoices/entry/:id', name: 'Entry', element: InvoiceEntry },
  { path: '/invoices/customer', name: 'Invoice', element: InvoiceCustomerMaster },
  { path: '/invoices/view-details/:id', name: 'Details', element: InvoiceViewDetails },

  { path: '/package', name: 'Package', element: PackageMaster },
  { path: '/package/entry', name: 'Package', element: PackageEntry },

  { path: '/users', name: 'Users', element: UserMaster },
  { path: '/users/entry', name: 'Entry', element: UserEntry },
  
  { path: '/roles', name: 'Role Management', element: RoleManagement },

  { path: '/account/details', name: 'Details', element: AccountDetails },

  { path: '/account/kyc', name: 'KYC', element: AccountKYC },

  { path: '/account/users', name: 'Users', element: AccountUsers },
  { path: '/account/users/details', name: 'Details', element: AccountUserDetails },
  { path: '/account/users/entry', name: 'Entry', element: AccountCreateUser },

  { path: '/account/vehicles', name: 'Vehicles', element: AccountVehicles },
  { path: '/account/vehicles/details', name: 'Details', element: AccountVehicleDetails },
  { path: '/account/vehicles/entry', name: 'Entry', element: AccountCreateVehicle },

  { path: '/account/drivers', name: 'Drivers', element: AccountDrivers },
  { path: '/account/drivers/details', name: 'Details', element: AccountDriverDetails },
  { path: '/account/drivers/entry', name: 'Entry', element: AccountCreateDriver },

  { path: '/account/contact', name: 'Contact', element: AccountContact },
  { path: '/account/contact/details', name: 'Details', element: AccountContactDetails },

  { path: '/account/contracts', name: 'Contracts', element: AccountContracts },
  { path: '/account/contracts/details', name: 'Details', element: AccountContractDetails },

  { path: '/account/gst', name: 'GST', element: AccountGST },

  { path: '/account/logout', name: 'Logout', element: Logout },  
]

export default routes
