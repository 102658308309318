import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    totalPermissions:0,
    roleManagement:[]
}

export const roleMgmt = createSlice({
    name: 'roleMgmt',
    initialState,
    reducers: {
        setInitialGlobalState: (state, action) => {
            state.roleManagement = action?.payload?.map((i)=>({
                id:i?.id,
                name:i?.name,
                globalSelect:false
            }));
        },
        roleActionGlobalSelectState: (state, action) => {
            let roles = state.roleManagement?.map((i)=>{
                if (action.payload.name === i?.name){
                    return { ...i, globalSelect:action?.payload?.globalSelect };
                }
                return { ...i };
            })
            state.roleManagement = roles;
        },
        setTotalPermissionCountState: (state, action) => {
            let permissionCount = action?.payload?.permission?.map((i)=>i?.permissions?.map((j)=>j?.id))?.reduce((acc, curr) => acc.concat(curr), [])?.length;
            state.totalPermissions = permissionCount;
            let currRole = action?.payload?.currentRole;
            if (currRole?.permissions?.length === state?.totalPermissions){
                let roles = state.roleManagement?.map((i)=>{
                    if (currRole?.name === i?.name){
                        return { ...i, globalSelect:true };
                    }
                    return { ...i };
                });
                state.roleManagement = roles;
            }
        },
        setGlobalSelectOnCount: (state, action) => {
            let currRole = action?.payload;
            if (currRole?.permissions?.length !== state?.totalPermissions){
                let roles = state.roleManagement?.map((i)=>{
                    if (currRole?.name === i?.name){
                        return { ...i, globalSelect:false };
                    }
                    return { ...i };
                });
                state.roleManagement = roles;
            }
        }
    },
});

export const {
    setInitialGlobalState,
    roleActionGlobalSelectState,
    setTotalPermissionCountState,
    setGlobalSelectOnCount
} = roleMgmt.actions;

export default roleMgmt.reducer;