import React, { useEffect } from 'react';
import fetchRequest from 'src/network';
import { swalError } from '../sweetPopup';
import { useDispatch } from 'react-redux';
import {
    bookingRuleSetSuccess,
    bookingRuleSetFailure
} from 'src/redux/reducers/bookingRules.reducer';
import { locStateSetFailure, locStateSetSuccess } from 'src/redux/reducers/locState.reducer';
import { isNotNull } from '../constants';

const withBookingRulesAndState = (WrappedComponent) => {
    return function () {

        const dispatch = useDispatch();

        useEffect(() => {
            fetchRequest.getAll(['/booking/ruleset', '/service-location/state'])
                .then((responses)=>{
                    if (responses?.length > 0){
                        if (responses?.[0]?.status_code === 200) {
                            dispatch(bookingRuleSetSuccess(responses?.[0]?.data));
                        } else {
                            dispatch(bookingRuleSetFailure(responses?.[0]));
                            swalError(responses?.[0]);
                        }
                        if (responses?.[1]?.status_code === 200) {
                            dispatch(locStateSetSuccess(responses?.[1]?.data?.results));
                        } else {
                            dispatch(locStateSetFailure(responses?.[1]));
                            swalError(responses?.[1]);
                        }
                    }
                })
                .catch((errors)=>{
                    if (errors?.length > 0) {
                        if (isNotNull(errors?.[0])){
                            dispatch(bookingRuleSetFailure(errors?.[0]?.message));
                            swalError(errors?.[0]);
                        }
                        if (isNotNull(errors?.[1])) {
                            dispatch(locStateSetFailure(errors?.[1]?.message));
                            swalError(errors?.[1]);
                        }
                    }
                });
        }, [dispatch]);

        return (
            <>
                <WrappedComponent />
            </>
        )
    }
}

export default withBookingRulesAndState;